@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy Thin Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Light\ Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Xlight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Xlight\ Italic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Bold\ Italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Medium.otf') format('opentype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Medium\ Italic.otf') format('opentype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ SemiBold\ Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Black.otf') format('opentype');
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Black\ Italic.otf') format('opentype');
  font-weight: 950;
  font-style: italic;
}


@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy\ Heavy\ Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy XBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy XBold Italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'SVN-Gilroy',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stroke-text {

  color: #FDF4DB; /* Fill color of the text */
  text-shadow: 0.5px 0.5px 0 #7A4C1C, -0.5px 0.5px 0 #7A4C1C, 0.5px -0.5px 0 #7A4C1C, -0.5px -0.5px 0 #7A4C1C;
}
/* styles.css */
.custom-radio .ant-radio-inner {
  background-color: #142548; /* Màu nền khi không chọn */
  border-color: #142548;
  
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  background-color: #142548;
  border-color: #142548;
}

.custom-radio .ant-radio-inner:after {
  background-color: white; 
}
.stroke-text {

  color: #FDF4DB;
  text-shadow: 0.5px 0.5px 0 #7A4C1C, -0.5px 0.5px 0 #7A4C1C, 0.5px -0.5px 0 #7A4C1C, -0.5px -0.5px 0 #7A4C1C;
}
/* antd radio button css */
.custom-radio .ant-radio-inner {
  background-color: #142548;
  border-color: #142548;
  
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  background-color: #142548;
  border-color: #142548;
}

.custom-radio .ant-radio-inner:after {
  background-color: white; 
}
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}
/* antd menu css */
.ant-menu-item {
  color: #65AFFF !important; 
  padding: 0px 40px !important; 
}
.ant-menu-item-selected {
  background-color: #D5EAFE !important; /* Light blue for selected */
  color: "red";
}

.ant-menu-item:hover {
  background-color: #D5EAFE !important; /* Light hover effect */
}


@keyframes fadeIn {
  to {
    opacity: 1;
  }
}